import React from "react";
import styled from "styled-components";
import { AiFillCheckCircle } from "react-icons/ai";
import { useState } from "react";
import { useContext } from "react";
import MyContext from "../utils/contexts/MyContext";
import { useEffect } from "react";
import CustomForm from "../components/CustomForm/CustomForm";
import { media } from "../utils/media";
import { useNavigate } from "react-router-dom";

const Sub = ({ onSubmitResultToCT = () => null }) => {
  const [showSubscribeButton, setshowSubscribeButton] = useState(true);
  const [textli1, settextli1] = useState([]);
  const [buttonClicked, setButtonClicked] = useState(false);
  const [dataSubmit, setDataSubmit] = useState(false);
  const [textli2, settextli2] = useState([]);
  const [formData, setFormData] = useState({
    Name: window.localStorage.getItem("user_name"),
    Email: window.localStorage.getItem("email"),
    Phone:
      window.localStorage.getItem("phone") !== "null"
        ? window.localStorage.getItem("phone")
        : null,
  });
  const { currentreportid, setcurrentreportid } = useContext(MyContext);
  const { reportplayers, setreportplayers } = useContext(MyContext);

  const handleChange = (name, value) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };
const navigate = useNavigate();

  const fields = [
    { name: "Name", type: "text", style: { backgroundColor: "white" } }, // Customize 'email' field background color
    { name: "Company", type: "text", style: { backgroundColor: "white" } },
    { name: "Email", type: "text", style: { backgroundColor: "white" } },
    { name: "Phone", type: "text", style: { backgroundColor: "white" } },
  ];
  let forceSignOut = () => {
        localStorage.clear();
        navigate("/signin3")
     
  };


  useEffect(() => {
    let prop_token = window.localStorage.getItem("token");
    let currreportid = window.localStorage.getItem("rep_id");
  
    fetch(
      `${process.env.REACT_APP_API_ENDPOINT}/getdescription/?rep_id=${currreportid}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${prop_token}`,
        },
      }
    )
      .then((res) => {
        if (res.status === 401) {
          forceSignOut();
          return;
        }
        return res.json();
      })
      .then((res) => {
        if (res) {
          let list = res["description_list"];
          settextli1(list);
        }
      })
      .catch((err) => {
        console.error("Error fetching description:", err);
      });
  }, []);

  const handleSubmit = () => {
    onSubmitResultToCT(true);
    const uploadData = new FormData();
    uploadData.append("name", formData.Name);
    uploadData.append("phone", formData.Phone);
    uploadData.append("email", formData.Email);
    uploadData.append("company", formData.Company);
    uploadData.append("report", window.localStorage.getItem("report"));
    fetch(`${process.env.REACT_APP_API_ENDPOINT}/userpopup/`, {
      method: "POST",
      body: uploadData,
    })
      .then((data) => data.json())
      .then((data) => {
        setButtonClicked(false);
        setDataSubmit(true);
      })
      .catch((error) => console.error(error));
  };

  let defaultplayers = [
    "Amazon",
    "Flipkart",
    "Myntra",
    "Meesho",
    "Nykaa",
    "Purplle",
    "Pepperfry",
    "Bigbasket",
    "Blinkit",
    "Dunzo",
  ];

  return (
    <Wrapper>
      <div style={{ background: "white" }}>
        <Viewdiv> To view more, please subscribe to</Viewdiv>
        <H4div>
          {" "}
          {window.localStorage.getItem("report")} Premium Subscription
        </H4div>
        <Subheaddiv> </Subheaddiv>
        <FirstDiv>
          <img src="/Images/deskman.png" alt="deskman" />
          <div>
            <H6div>
              What you get from Premium Subscription ?
            </H6div>
            {textli1?.length > 0 ? (
              textli1
                ?.filter((val) => val !== "")
                ?.map((val, index) => (
                  <div key={index} className="subdiv">
                    <AiFillCheckCircle style={{ color: "green" }} />
                    {val}
                  </div>
                ))
            ) : (
              <div>
                <div>
                  <AiFillCheckCircle style={{ color: "green" }} />
                  Platform metrics (includes Gross and Shipped: GMV, Items and
                  ASP with a Category filter)
                </div>
                <div>
                  <AiFillCheckCircle style={{ color: "green" }} />
                  Geographic view of - Platform wise performance of GMV &
                  Leaders overall India
                </div>
                <div>
                  <AiFillCheckCircle style={{ color: "green" }} />
                  Industry cancellation metrics
                </div>
                <div>
                  <AiFillCheckCircle style={{ color: "green" }} />
                  Platform wise User & Order Metrics
                </div>
              </div>
            )}
          </div>
        </FirstDiv>

        <Thirddiv>
          <div>
            {reportplayers.length > 0 ? <h6>Players we cover :</h6> : null}
            <Imagesdiv>
              {reportplayers?.map((val, index) => (
                <div key={index}>
                  {val.image == null ? (
                    val.icon
                  ) : (
                    <img src={val.image} alt="" />
                  )}
                </div>
              ))}
            </Imagesdiv>
          </div>
        </Thirddiv>
        {/* <Requestdiv style={{
                visibility: buttonClicked ? 'visible' : 'hidden',
                opacity: buttonClicked ? 1 : 0,
                transition: 'opacity 0.3s'
            }}>
                You request has been successfully recorded. We will contact you soon at {window.localStorage.getItem('email')}
            </Requestdiv> */}

        {buttonClicked && (
          <Overlay>
            <Modal>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginBottom: "20px",
                  padding: "10px",
                  paddingLeft: "50px",
                  borderBottom: "1px solid #C9C9C9",
                }}
              >
                <div style={{ fontWeight: 500 }}>
                  Please Enter Your Details :
                </div>
                <CloseButton
                  onClick={() => {
                    onSubmitResultToCT(false);
                    setButtonClicked(false);
                  }}
                >
                  X
                </CloseButton>
              </div>
              <FormDiv>
                <CustomForm
                  fields={fields}
                  onChange={handleChange}
                  formData={formData}
                />
                <button style={{ width: "100%" }} onClick={handleSubmit}>
                  Submit
                </button>
              </FormDiv>
            </Modal>
          </Overlay>
        )}

        <Subscribediv
          style={{
            visibility: buttonClicked ? "hidden" : "visible",
            opacity: buttonClicked ? 0 : 1,
            transition: "opacity 0.3s",
          }}
        >
          {dataSubmit ? null : (
            <Subscribetextdiv>
              Click on the Subscribe button and our sales team will contact you
              soon
            </Subscribetextdiv>
          )}
        </Subscribediv>
        <SubButtondiv>
          {dataSubmit ? (
            <button style={{ background: "#20af49" }}>
              Subscription requested
            </button>
          ) : (
            <button
              style={{ background: "#0099ff" }}
              onClick={() => {
                setButtonClicked(!buttonClicked);
              }}
            >
              Subscribe
            </button>
          )}
        </SubButtondiv>
      </div>
    </Wrapper>
  );
};

export default Sub;

const Wrapper = styled.div`
  padding: 10px 3.5vw;
  background-color: #eef9ff;
  ${media.small`
    font-size:12px;
    `}
`;

const H4div = styled.h4`
  text-align: center;
  padding: 30px;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-bottom: 0px;
  ${media.small`
    font-size:15px;
    `}
`;

const H6div=styled.h6`
font-size: 18px;
${media.small`
  font-size:14.5px;
  `}
`

const Subscribetextdiv=styled.div`
${media.small`
  font-size:12px;
  `}

`



const Subheaddiv = styled.div`
  padding: 5px;
  padding-bottom: 10px;
  text-align: center;
  font-weight: 500;
  font-size: 18px;
`;

const FirstDiv = styled.div`
  display: flex;
  padding: 40px;
  padding-bottom: 0px;
  padding-top: 0px;
  gap: 120px;
  justify-content: center;
  align-items: center;
  img {
    display: none;
    @media (min-width: 768px) {
      display: block;
    }
  }
    ${media.small`
        padding: 20px;

      
      `}
`;

const SecondDiv = styled.div`
  display: flex;
  padding: 40px;
  gap: 120px;
  padding-top: 0px;
  justify-content: center;
  align-items: center;
  img {
    display: none;
    @media (min-width: 768px) {
      display: block;
    }
  }
     ${media.small`
        padding: 0;
      `}
`;

const Thirddiv = styled.div`
  padding: 40px;
  padding-bottom: 0px;
  img {
    display: none;
    @media (min-width: 768px) {
      display: block;
    }
  }
    ${media.small`
        padding: 0;
      `}
`;

const Imagesdiv = styled.div`
  display: grid;
  div {
    img {
      height: 80px !important;
      width: auto !important;
    }
  }

  grid-auto-columns: 1fr;
  grid-auto-rows: 1fr;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-template-rows: auto;
  gap: 10px 10px;
  grid-template-areas: ". . .";
`;

const Subscribediv = styled.div`
  padding: 30px;
  padding-top: 5px;
  text-align: center;
  font-size: 15px;
`;

const SubButtondiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 20px;
  button {
    color: white;
    border-radius: 50px;
    width: 200px;
    height: 50px;
    border: none;
  }
    ${media.small`
        padding-bottom: 15px;
 button {
    color: white;
    border-radius: 50px;
    width: 100px;
    height: 30px;
    border: none;
  }
      
      
      `}
`;

const Requestdiv = styled.div`
  text-align: center;
  padding: 10px;
`;

const Viewdiv = styled.div`
  text-align: center;
  font-weight: 500;
  font-size: 18px;
  padding-top: 50px;
  ${media.small`
    font-size:14px;
      padding-top: 30px;

    `}
`;

const FormDiv = styled.div`
  padding-left: 50px !important;
  padding-right: 50px !important;
  padding-bottom: 20px;
  @media (min-width: 768px) {
    padding-left: 2vw;
    padding-right: 2vw;
  }

  & button {
    background-color: #0099fe;
    line-height: 40px;
    border-radius: 50px;
    border: none;
    margin-top: 20px;
    color: white;
  }
`;

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 5;
`;

const CloseButton = styled.button`
  background-color: white;
  border: none;
  font-size: 16px;
`;

const Modal = styled.div`
  background: white;
  border-radius: 8px;
  width: 500px;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.2);
`;
